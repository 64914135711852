export const ROLES = {
  HOD: 'hod',
  PGT: 'pgt',
  TGT: 'tgt',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  HOD: 'hod',
  TGT: 'tgt',
  PGT: 'pgt',
};

export const QUESTION_CATEGORIES = {
  one_word: 'One Word',
  abbreviations: 'Abbreviations',
  true_or_false: 'True Or False',
  multiple_choice: 'Multiple Choice',
  application_based: 'Application Based',
  fill_in_the_blanks: 'Fill In The Blanks',
  match_the_following: 'Match the Following',
  short_answer: 'Short Answer',
  long_answer: 'Long Answer',
};

export const ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const USER_ROLES_MAPPER = {
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.HOD]: 'Head of Department',
  [USER_ROLES.TGT]: 'TGT',
  [USER_ROLES.PGT]: 'PGT',
};
